import Cookies from 'js-cookie'
import { constants } from '~/config/constants'

const { GLOBAL_SETTINGS_COOKIE } = constants

const cookie = Cookies.get(GLOBAL_SETTINGS_COOKIE)

const analytics = {
    // push global data that should be sent for every page
    startup() {
        window.dataLayer = window.dataLayer ?? []
        cookie && this.push({ user_id: getGlobalUserIdFromCookie(cookie) })
    },

    // Push a data object to the analytics provider(s) (GTM, etc)
    // This can either be a data variable or an event.
    // We use GTM syntax for the properties of the object.
    // See https://developers.google.com/tag-platform/tag-manager/datalayer
    push(dataObj: any) {
        window.dataLayer.push(dataObj)
    },
}

function getGlobalUserIdFromCookie(cookie: string) {
    try {
        const decodedCookie = window.atob(cookie)

        return JSON.parse(decodedCookie).gid
    } catch (e) {
        console.error('Failed to get global user id from cookie. Error: ', e)
        return ''
    }
}

export default analytics
